import React from 'react'
import Layout from 'gatsby-theme-tailwindui/src/components/layout'
import { useQueryParam, StringParam } from 'use-query-params'
import Fuse from 'fuse.js'
import { Link } from 'gatsby'
import { useSiteMetadata } from 'gatsby-theme-tailwindui/src/utils/use-site-metadata'
import QuotePageLayout from '../components/quotes/QuotePageLayout'
import QuoteComponent from '../components/quotes/QuoteComponent'

const options = {
  isCaseSensitive: false,
  includeScore: true,
  distance: 500,
  threshold: 0.4,
  keys: [
    {
      name: 'testimonial',
      weight: 1,
    },
    {
      name: 'author.name',
      weight: 1,
    },
    {
      name: 'author.authorInfo',
      weight: 0.5,
    },
    {
      name: 'testimonialCategory.title',
      weight: 0.5,
    },
  ],
}

const SearchQuotes = (props) => {
  const { quotes } = props.pageContext
  const [query, setQuery] = useQueryParam('query', StringParam)
  const { globalNavMenu, globalContactMenu } = useSiteMetadata()
  const menuItems = globalNavMenu
  const contactMenu = globalContactMenu
  const fuse = new Fuse(quotes, options)

  const filteredQuotes = fuse.search(query || '')
  return (
    <Layout navMenuItems={menuItems} contactMenuItems={contactMenu}>
      <QuotePageLayout>
        <h2 className="no-print">Search results for : "{query}"</h2>
        <Link to="/quotes">Show all</Link>

        {filteredQuotes
          .map((v) => v.item)
          .map((quote, i) => (
            <QuoteComponent key={i} data={quote} />
          ))}
      </QuotePageLayout>
    </Layout>
  )
}

export default SearchQuotes
